import {
  List,
  Datagrid,
  TextField,
  ResourceComponentProps,
  Create,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";

const ApiKeysListFilters = [
  <TextInput source="id" />,
  <TextInput source="account" />
];

export const ApiKeyList = (props: ResourceComponentProps) => (
  <List {...props} filters={ApiKeysListFilters}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="createdAt" />
      <TextField source="account" />
    </Datagrid>
  </List>
);

export const ApiKeyCreate = (props: ResourceComponentProps) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="apiKey" validate={required()} />
      <TextInput source="account" validate={required()} />
    </SimpleForm>
  </Create>
);
