import {
  List,
  Datagrid,
  TextField,
  ResourceComponentProps,
} from "react-admin";

export const LogList = (props: ResourceComponentProps) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="createdAt" />
      <TextField source="type" />
      <TextField source="description" />
    </Datagrid>
  </List>
);
