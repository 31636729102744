import { Layout, AppBar, UserMenu, LayoutProps, AppBarProps, UserMenuProps, defaultTheme } from "react-admin";
import { Auth } from "aws-amplify";
import { ListItemIcon, MenuItem, Box } from "@material-ui/core";
import merge from "lodash/merge";
import LogoutIcon from "@material-ui/icons/PowerSettingsNew";
import LogoImage from "./Logo.png";

const AmplifyLogoutButton = () => (
    <MenuItem
        onClick={() => { Auth.signOut().then(() => { window.location.replace("/") }) }}>
        <ListItemIcon >
            <LogoutIcon />
        </ListItemIcon>
        {"Logout"}
    </MenuItem>
)

const CustomUserMenu = (props: UserMenuProps) => <UserMenu {...props} logout={<AmplifyLogoutButton />} />
const CustomAppBar = (props: AppBarProps) => (
    <AppBar {...props} userMenu={<CustomUserMenu />}>
        <Box flex={1}>
            <img src={LogoImage} alt="PLUX" style={{ height: "25px" }} />
        </Box>
    </AppBar>
);

const customTheme = merge({}, defaultTheme, {
    palette: {
        secondary: {
            contrastText: "#fff",
            dark: "#0069c0",
            light: "#6ec6ff",
            main: "#657bbc"
        },
    },
});

export const CustomLayout = (props: LayoutProps) => <Layout {...props} appBar={CustomAppBar} theme={customTheme} />;