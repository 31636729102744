import axios from "network";
import {
  List,
  Datagrid,
  TextField,
  ResourceComponentProps,
  Create,
  SimpleForm,
  TextInput,
  required,
  Edit,
  ReferenceInput,
  SelectInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  useNotify,
  TabbedForm,
  FormTab,
  FieldProps,
  TopToolbar,
  EditActionsProps,
  useRedirect,
} from "react-admin";
import { Box, ListItem, ListItemText, Button, ListItemAvatar, Avatar, TextField as MuiTextField } from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import { useState } from "react";

export class BookingUpdateCommand {
  private workshopId: string;
  private optionId: string;
  private slotId: string;
  private address: string;
  private latitude: string;
  private longitude: string;
  private answers: object;

  constructor(data: any) {
    this.workshopId = data.workshop?.id;
    this.optionId = data.option?.id;
    this.slotId = data.slot?.id;
    this.address = data.address;
    this.latitude = data.latitude;
    this.longitude = data.longitude;
    this.answers = data.answers;
  }

  static fromJson(data: any) {
    return new BookingUpdateCommand(data);
  }
}

const BookingsListFilters = [
  <ReferenceInput source="userId" reference="users" perPage={100}>
    <SelectInput optionText={(record: any) => `${record.firstName} ${record.lastName} (${record.email})`} optionValue="id" />
  </ReferenceInput>,
  <ReferenceInput source="serviceId" reference="services" perPage={100}>
    <SelectInput optionText={(record: any) => record.name} optionValue="id" />
  </ReferenceInput>,
  <ReferenceInput source="vehicleId" reference="vehicles" perPage={100}>
    <SelectInput optionText={(record: any) => record.name} optionValue="id" />
  </ReferenceInput>,
  <ReferenceInput source="categoryId" reference="categories" perPage={100}>
    <SelectInput optionText={(record: any) => record.name} optionValue="id" />
  </ReferenceInput>,
  <ReferenceInput source="workshopId" reference="workshops" perPage={100}>
    <SelectInput optionText={(record: any) => record.name} optionValue="id" />
  </ReferenceInput>,
  <TextInput source="salesforceId" label="Salesforce ID" />,
];

export const BookingList = (props: ResourceComponentProps) => (
  <List {...props} filters={BookingsListFilters}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="createdAt" />
      <TextField source="status" />
      <TextField source="userId" />
      <TextField source="service.name" label="Service" sortable={false} />
      <TextField source="workshop.name" label="Workshop" sortable={false} />
      <TextField source="salesforceId" label="Salesforce ID" />
    </Datagrid>
  </List>
);

export const BookingCreate = (props: ResourceComponentProps) => (
  <Create {...props}>
    <SimpleForm redirect="edit">
      <ReferenceInput source="userId" reference="users" validate={required()} perPage={100}>
        <SelectInput optionText={(record: any) => `${record.firstName} ${record.lastName} (${record.email})`} optionValue="id" />
      </ReferenceInput>
      <ReferenceInput source="serviceId" reference="services" validate={required()} perPage={100}>
        <SelectInput optionText={(record: any) => record.name} optionValue="id" />
      </ReferenceInput>
      <ReferenceInput source="vehicleId" reference="vehicles" validate={required()} perPage={100}>
        <SelectInput optionText={(record: any) => `${record.plateNumber} (${record.userId ?? record.user?.id})`} optionValue="id" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

const BookingEditActions = (props: EditActionsProps) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const submitBooking = async () => {
    const bookingId = props.data?.id;

    if (bookingId) {
      try {
        await axios.post(`bookings/${bookingId}/submit`);
        redirect("list", props.basePath);
      } catch (error: any) {
        notify(`Could not submit booking: ${error.message}`);
      }
    }
  }

  return (
    <TopToolbar>
      <Button variant="contained" color="primary" onClick={() => submitBooking()}>
        Submit
      </Button>
    </TopToolbar>
  );
};

export const BookingEdit = (props: ResourceComponentProps) => {
  const notify = useNotify();

  const onFailure = (error: any) => {
    notify(`Could not edit booking: ${error.message}`);
  };

  return (
    <Edit {...props} actions={<BookingEditActions />} mutationMode="pessimistic" onFailure={onFailure}>
      <TabbedForm redirect="edit">
        <FormTab label="Information">
          <TextField source="id" />
          <TextField source="createdAt" />
          <TextField source="scheduledAt" />
          <TextField source="status" />
          <TextField source="user.id" label="User ID" />
          <TextField source="user.firstName" label="User name" />
          <TextField source="salesforceId" label="Salesforce ID" />
          <TextField source="service.name" label="Service" />
          <ReferenceInput source="workshop.id" reference="workshops" perPage={100} label="Workshop">
            <SelectInput optionText={(record: any) => record.name} optionValue="id" />
          </ReferenceInput>
          <TextInput source="option.id" />
          <TextInput source="slot.id" />
          <TextInput source="address" />
          <NumberInput source="latitude" />
          <NumberInput source="longitude" />

          <ArrayInput source="answers" defaultValue={null}>
            <SimpleFormIterator>
              <TextInput source="stepId" validate={required()} label="Step ID" />
              <ArrayInput source="questions" validate={required()} label="Questions">
                <SimpleFormIterator>
                  <TextInput source="questionId" validate={required()} label="Question ID" />
                  <ArrayInput source="values" validate={required()} label="Values">
                    <SimpleFormIterator>
                      <TextInput source="" validate={required()} label="Value" />
                    </SimpleFormIterator>
                  </ArrayInput>
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="options">
          <BookingOptions />
        </FormTab>

        <FormTab label="slots">
          <BookingSlots />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

interface Booking {
  id: string;
}

interface BookingOption {
  id: string;
  title: string;
  description: string;
  price: string;
  currency: string;
  optionImage?: string;
}

interface BookingSlot {
  id: string;
  date: string;
  startTime: string;
  endTime: string;
}

const BookingOptions = (props: FieldProps<Booking>) => {
  const notify = useNotify();
  const [options, setOptions] = useState([]);

  const getBookingOptions = async () => {
    const bookingId = props.record?.id;

    if (bookingId) {
      setOptions([]);

      try {
        const response = await axios.get(`bookings/${bookingId}/options`);
        setOptions(response.data);
      } catch (error: any) {
        notify(`Could not get options: ${error.message}`);
      }
    }
  }

  return (
    <Box>
      <Button variant="contained" color="primary" onClick={() => getBookingOptions()}>
        Get Options
      </Button>
      {options.map((option: BookingOption) => (
        <ListItem key={option.id}>
          <ListItemAvatar>
            <Avatar>
              {option.optionImage ? <img src={option.optionImage} alt="Option" style={{ height: "40px" }} /> : <ImageIcon />}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`${option.title} - ${option.description} (${option.price} ${option.currency})`}
            secondary={option.id}
          />
        </ListItem>
      ))}
    </Box>
  );
}

const BookingSlots = (props: FieldProps<Booking>) => {
  const notify = useNotify();
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const [slots, setSlots] = useState([]);

  const getBookingSlots = async () => {
    const bookingId = props.record?.id;

    if (bookingId && startDate && endDate) {
      setSlots([]);

      try {
        const response = await axios.get(`bookings/${bookingId}/slots?startDate=${new Date(startDate).toISOString()}&endDate=${new Date(endDate).toISOString()}`);
        setSlots(response.data);
      } catch (error: any) {
        notify(`Could not get slots: ${error.message}`);
      }
    }
  }

  return (
    <Box>
      <Box>
        <MuiTextField type="date" onChange={event => setStartDate(event.target.value)} />
        <MuiTextField type="date" onChange={event => setEndDate(event.target.value)} />
        <Button variant="contained" color="primary" disabled={!startDate || !endDate} onClick={() => getBookingSlots()}>
          Get Slots
        </Button>
      </Box>
      {slots.map((slot: BookingSlot) => (
        <ListItem key={slot.id}>
          <ListItemAvatar>
            <Avatar>
              <ImageIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`${slot.date} ${slot.startTime}-${slot.endTime}`}
            secondary={slot.id}
          />
        </ListItem>
      ))}
    </Box>
  );
}
