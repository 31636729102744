import axios from "network";
import {
  List,
  Datagrid,
  TextField,
  ResourceComponentProps,
  Create,
  SimpleForm,
  TextInput,
  required,
  Edit,
  ReferenceInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  FormDataConsumer,
  NumberInput,
  SelectArrayInput,
  useNotify,
  FieldProps,
  TranslatableInputs
} from "react-admin";
import { useState } from "react";
import { Box, ListItem, ListItemText, ListItemAvatar, Avatar, Input, IconButton, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

export class WorkflowUpdateCommand {
  private status?: string;
  private serviceId: string;
  private order: number;
  private steps: object;

  constructor(data: any) {
    this.status = data.status;
    this.serviceId = data.service?.id ?? "";
    this.order = data.order ?? 0;
    this.steps = data.steps?.map((step: any) => {
      const questions = step.questions?.map((question: any) => {
        const questionOutput: any =  {
          id: question.id,
          type: question.type,
          fieldMapping: question.fieldMapping,
          required: question.required,
          title: question.title,
        };

        if (question.type === "Option") {
          questionOutput.multiple = question.multiple;
          questionOutput.dataType = question.dataType;
          questionOutput.inputType = question.inputType;
          questionOutput.options = question.options.map((option: any) => ({
            id: option.id,
            value: option.value,
            text: option.text,
            filter: option.filter,
            icon: option.icon,
          }));
        } else if (question.type === "NumberPicker") {
          questionOutput.dataType = question.dataType;
          questionOutput.min = question.min;
          questionOutput.max = question.max;
        } else if (question.type === "FileUpload") {
          questionOutput.allowedMimeTypes = question.allowedMimeTypes;
          questionOutput.maxSize = question.maxSize;
          questionOutput.maxFiles = question.maxFiles;
        } else if (question.type === "TextArea") {
          questionOutput.dataType = question.dataType;
          questionOutput.hint = question.hint;
          questionOutput.minChars = question.minChars;
          questionOutput.maxChars = question.maxChars;
        }

        return questionOutput;
      }) ?? [];

      return {
        id: step.id,
        title: step.title,
        subtitle: step.subtitle,
        type: step.type,
        tooltipId: step.tooltipId,
        filters: step.filters,
        questions
      };
    }) ?? [];
  }

  static fromJson(data: any) {
    return new WorkflowUpdateCommand(data);
  }
}

const WorkflowStatus = [
  { id: "Pending", name: "Pending" },
  { id: "Active", name: "Active" },
];

const WorkflowStepType = [
  { id: "Input", name: "Input" },
  { id: "Location", name: "Location" },
  { id: "Workshop", name: "Workshop" },
  { id: "Option", name: "Option" },
  { id: "Slot", name: "Slot" },
];

const QuestionInputType = [
  { id: "Option", name: "Option" },
  { id: "NumberPicker", name: "Number Picker" },
  { id: "FileUpload", name: "File Upload" },
  { id: "TextArea", name: "Text Area" },
];

const QuestionMapping = [
  { id: "Category", name: "Category" },
  { id: "Diagnostic", name: "Diagnostic" },
  { id: "TireWidth", name: "Tire Width" },
  { id: "TireHeight", name: "Tire Height" },
  { id: "TireDiameter", name: "Tire Diameter" },
  { id: "TireQuantity", name: "Tire Quantity" },
  { id: "RunFlat", name: "Run Flat" },
  { id: "StartStop", name: "Start Stop" },
  { id: "Battery3Years", name: "Battery 3 Years" },
  { id: "Kilometers", name: "Kilometers" },
  { id: "Description", name: "Description" },
  { id: "Photos", name: "Photos" }
];

const DataType = [
  { id: "String", name: "String" },
  { id: "Int", name: "Int" },
  { id: "Double", name: "Double" },
];

const InputType = [
  { id: "List", name: "List" },
  { id: "Scale", name: "Scale" },
  { id: "Checkbox", name: "Checkbox" },
];

const AllowedMimeTypes = [
  { id: "image/jpeg", name: "image/jpeg" },
  { id: "image/png", name: "image/png" },
];

const WorkflowsListFilters = [
  <TextInput source="id" />,
  <TextInput source="serviceId" />
];

export const WorkflowList = (props: ResourceComponentProps) => (
  <List {...props} filters={WorkflowsListFilters}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="createdAt" />
      <TextField source="status" />
      <TextField source="service.name" label="Service" sortable={false} />
      <TextField source="order" />
    </Datagrid>
  </List>
);

export const WorkflowCreate = (props: ResourceComponentProps) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput source="serviceId" reference="services" validate={required()} perPage={100} label="Service">
        <SelectInput optionText={(record: any) => record.name} optionValue="id" />
      </ReferenceInput>
      <NumberInput source="order" label="Order" validate={required()} />
    </SimpleForm>
  </Create>
);

export const WorkflowEdit = (props: ResourceComponentProps) => {
  const notify = useNotify();

  const onFailure = (error: any) => {
      notify(`Could not edit workflow: ${error.message}`);
  };

  return (
    <Edit {...props} mutationMode="pessimistic" onFailure={onFailure}>
      <SimpleForm>
        <TextField source="id" />
        <TextField source="createdAt" />
        <SelectInput
          source="status"
          options={{ label: "Status" }}
          choices={WorkflowStatus}
          validate={required()}
        />
        <ReferenceInput source="service.id" reference="services" validate={required()} perPage={100} label="Service">
          <SelectInput optionText={(record: any) => record.name} optionValue="id" />
        </ReferenceInput>
        <NumberInput source="order" label="Order" validate={required()} />

        <WorkflowFiles />
  
        <ArrayInput source="steps" validate={required()} label="Steps">
          <SimpleFormIterator>
            <TextInput source="id" disabled label="Step ID" />
            <FormDataConsumer>
              {({ getSource }) => getSource && (
                <TranslatableInputs locales={['pt','en']}>
                  <TextInput source={getSource("title")} label="Title" validate={required()} />
                  <TextInput source={getSource("subtitle")} label="Subtitle" />
                </TranslatableInputs>
              )}
            </FormDataConsumer>
            <TextInput source="tooltipId" label="Tooltip ID" />
            <SelectInput
              source="type"
              options={{ label: "Type" }}
              choices={WorkflowStepType}
              validate={required()}
            />
            <ArrayInput source="filters" label="Restrict to Filters?">
              <SimpleFormIterator>
                <TextInput source="" validate={required()} label="Filter" />
              </SimpleFormIterator>
            </ArrayInput>
  
            <ArrayInput source="questions" label="Questions">
              <SimpleFormIterator>
                <TextInput source="id" disabled label="Question ID" />
                <SelectInput
                  source="type"
                  options={{ label: "Type" }}
                  choices={QuestionInputType}
                  validate={required()}
                />
                <BooleanInput source="required" defaultValue={true} validate={required()} label="Is Required?" />
                <FormDataConsumer>
                  {({ getSource }) => getSource && (
                  <TranslatableInputs locales={['pt','en']}>
                    <TextInput source={getSource("title")} validate={required()} label="Title" />
                  </TranslatableInputs>
                  )}
                </FormDataConsumer>
                <SelectInput
                  source="fieldMapping"
                  options={{ label: "Field Mapping" }}
                  choices={QuestionMapping}
                  validate={required()}
                />
  
                <FormDataConsumer>
                  {({ getSource, scopedFormData }) => getSource && scopedFormData?.type === "Option" && (
                    <Box>
                      <BooleanInput source={getSource("multiple")} defaultValue={false} validate={required()} label="Multiple?" />
                      <SelectInput
                        fullWidth
                        source={getSource("dataType")}
                        options={{ label: "Data Type" }}
                        choices={DataType}
                        validate={required()}
                      />
                      <SelectInput
                        fullWidth
                        source={getSource("inputType")}
                        options={{ label: "Input Type" }}
                        choices={InputType}
                        validate={required()}
                      />
                      <ArrayInput source={getSource("options")} validate={required()} label="Options">
                        <SimpleFormIterator>
                          <TextInput source="id" disabled label="Option ID" />
                          <TextInput source="value" validate={required()} label="Salesforce Value" />
                          <FormDataConsumer>
                            {({ getSource }) => getSource && (
                            <TranslatableInputs locales={['pt','en']}>
                              <TextInput source={getSource("text")} validate={required()} label="Interface Text" />
                            </TranslatableInputs>
                            )}
                          </FormDataConsumer>
                          <TextInput source="icon" label="Icon" />
                          <TextInput source="filter" label="Add step filter" />
                        </SimpleFormIterator>
                      </ArrayInput>
                    </Box>
                  )}
                </FormDataConsumer>
  
                <FormDataConsumer>
                  {({ getSource, scopedFormData }) => getSource && scopedFormData?.type === "NumberPicker" && (
                    <Box>
                      <SelectInput
                        fullWidth
                        source={getSource("dataType")}
                        options={{ label: "Data Type" }}
                        choices={DataType}
                        validate={required()}
                      />
                      <NumberInput fullWidth source={getSource("min")} label="Min" />
                      <NumberInput fullWidth source={getSource("max")} label="Max" />
                    </Box>
                  )}
                </FormDataConsumer>
  
                <FormDataConsumer>
                  {({ getSource, scopedFormData }) => getSource && scopedFormData?.type === "FileUpload" && (
                    <Box>
                      <SelectArrayInput
                        fullWidth
                        source={getSource("allowedMimeTypes")}
                        label="Allowed File Types"
                        choices={AllowedMimeTypes}
                        validate={required()}
                      />
                      <NumberInput fullWidth source={getSource("maxSize")} label="Max Size" />
                      <NumberInput fullWidth source={getSource("maxFiles")} label="Max Files" />
                    </Box>
                  )}
                </FormDataConsumer>
  
                <FormDataConsumer>
                  {({ getSource, scopedFormData }) => getSource && scopedFormData?.type === "TextArea" && (
                    <Box>
                      <SelectInput
                        fullWidth
                        source={getSource("dataType")}
                        options={{ label: "Data Type" }}
                        choices={DataType}
                        validate={required()}
                      />
                      <TranslatableInputs locales={['pt','en']}>
                        <TextInput fullWidth source={getSource("hint")} label="Hint" />
                      </TranslatableInputs>
                      <NumberInput fullWidth source={getSource("minChars")} label="Min Chars" />
                      <NumberInput fullWidth source={getSource("maxChars")} label="Max Chars" />
                    </Box>
                  )}
                </FormDataConsumer>
  
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

interface Workflow {
  id: string;
  files: WorkflowFile[];
}

interface WorkflowFile {
  id: string;
  mimeType: string;
  url: string;
}

const WorkflowFiles = (props: FieldProps<Workflow>) => {
  const notify = useNotify();
  const [files, setFiles] = useState<WorkflowFile[]>(props.record?.files ?? []);

  const createFile = async (file: File) => {
    const workflowId = props.record?.id;

    if (workflowId) {
      try {
        const res = await axios.post(`workflows/${workflowId}/files`, file, { headers: { "Content-Type": "binary/octet-stream" } });
        setFiles([...files, res.data])
      } catch (error: any) {
        notify(`Could not create file: ${error.message}`);
      }
    }
  }

  const deleteFile = async (fileId: string) => {
    const workflowId = props.record?.id;

    if (workflowId) {
      try {
        await axios.delete(`workflows/${workflowId}/files/${fileId}`);
        setFiles(files.filter(it => it.id !== fileId));
      } catch (error: any) {
        notify(`Could not delete file: ${error.message}`);
      }
    }
  }

  return (
    <Box>
      <Typography component="div" style={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.54)" }}>
        Files
      </Typography>
      <Input
        type="file"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const file = event.target?.files?.[0];

          if (file) {
            createFile(file);
          }
        }}
      />
      {files.map((file: WorkflowFile) => (
        <ListItem key={file.id}>
          <ListItemAvatar>
            <Avatar>
              <img src={file.url} alt="File" style={{ height: "40px" }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`${file.id} (${file.mimeType})`}
            secondary={file.url}
          />
          <IconButton edge="end" aria-label="delete" onClick={() => deleteFile(file.id)}>
            <DeleteIcon />
          </IconButton>
        </ListItem>
      ))}
    </Box>
  );
}
