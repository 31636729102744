import { BookingUpdateCommand } from "app/resources/Bookings";
import { OperatorUpdateCommand } from "app/resources/Operators";
import { UserUpdateCommand } from "app/resources/Users";
import { VehicleUpdateCommand } from "app/resources/Vehicles";
import { WorkflowUpdateCommand } from "app/resources/Workflow";
import axios from "network";
import {
  GetListParams,
  DataProvider,
  CreateParams,
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  Record,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";

export class CustomDataProvider implements DataProvider {
  async getList<RecordType extends Record = Record>(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult<RecordType>> {
    let requestUrl = `/${resource}?page[number]=${
      params.pagination.page - 1
    }&page[size]=${params.pagination.perPage}&sort[${
      params.sort.field
    }]=${params.sort.order.toLowerCase()}`;

    if (params.filter) {
      for (var prop in params.filter) {
        if (Object.prototype.hasOwnProperty.call(params.filter, prop)) {
          requestUrl += `&filter[${prop}]=${params.filter[prop]}`;
        }
      }
    }

    const { meta, data } = (await axios.get(requestUrl)).data;
    return { data, total: meta.totalElements };
  }

  async getOne<RecordType extends Record = Record>(
    resource: string,
    params: GetOneParams
  ): Promise<GetOneResult<RecordType>> {
    const data = (await axios.get(`/${resource}/${params.id}`)).data;
    return { data };
  }

  async getMany<RecordType extends Record = Record>(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<RecordType>> {
    const promises = params.ids.map((id) => axios.get(`/${resource}/${id}`));
    const data = (await Promise.all(promises)).map((it) => it.data);
    return { data };
  }

  getManyReference<RecordType extends Record = Record>(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<RecordType>> {
    throw new Error("GetManyReference operation not implemented");
  }

  async update<RecordType extends Record = Record>(
    resource: string,
    params: UpdateParams
  ): Promise<UpdateResult<RecordType>> {
    let updateObject = {};
    switch (resource) {
      case "operators":
        updateObject = OperatorUpdateCommand.fromJson(params.data);
        break;
      case "users":
        updateObject = UserUpdateCommand.fromJson(params.data);
        break;
      case "vehicles":
        updateObject = VehicleUpdateCommand.fromJson(params.data);
        break;
      case "workflows":
        updateObject = WorkflowUpdateCommand.fromJson(params.data);
        break;
      case "bookings":
        updateObject = BookingUpdateCommand.fromJson(params.data);
        break;
      default:
        updateObject = params.data;
    }

    const data = (await axios.put(`/${resource}/${params.id}`, updateObject))
      .data;

    return { data: data ?? params.data };
  }

  async updateMany(
    resource: string,
    params: UpdateManyParams
  ): Promise<UpdateManyResult> {
    const promises = params.ids.map((id) =>
      axios.put(`/${resource}/${id}`, params.data)
    );
    await Promise.all(promises);
    return { data: params.ids };
  }

  async create<RecordType extends Record = Record>(
    resource: string,
    params: CreateParams
  ): Promise<CreateResult<RecordType>> {
    const data = (await axios.post(`/${resource}`, params.data)).data;
    return { data };
  }

  async delete<RecordType extends Record = Record>(
    resource: string,
    params: DeleteParams
  ): Promise<DeleteResult<RecordType>> {
    const data = (await axios.delete(`/${resource}/${params.id}`)).data;
    return { data };
  }

  async deleteMany(
    resource: string,
    params: DeleteManyParams
  ): Promise<DeleteManyResult> {
    const promises = params.ids.map((id) => axios.delete(`/${resource}/${id}`));
    await Promise.all(promises);
    return { data: params.ids };
  }
}
