import {
  List,
  Datagrid,
  TextField,
  ResourceComponentProps,
  Create,
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
  Edit,
  ImageField,
} from "react-admin";

export class VehicleUpdateCommand {
  private status: string;

  constructor(data: any) {
    this.status = data.status ?? "";
  }

  static fromJson(data: any) {
    return new VehicleUpdateCommand(data);
  }
}

const VehicleStatus = [
  { id: "Active", name: "Active" }
];

const VehiclesListFilters = [
  <TextInput source="plateNumber" />,
  <ReferenceInput source="userId" reference="users" perPage={100}>
    <SelectInput optionText={(record: any) => `${record.firstName} ${record.lastName} (${record.email})`} optionValue="id" />
  </ReferenceInput>,
];


export const VehicleList = (props: ResourceComponentProps) => (
  <List {...props} filters={VehiclesListFilters}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="createdAt" />
      <TextField source="status" />
      <TextField source="userId" />
      <TextField source="plateNumber" />
    </Datagrid>
  </List>
);

export const VehicleCreate = (props: ResourceComponentProps) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput source="userId" reference="users" validate={required()} perPage={100}>
        <SelectInput optionText={(record: any) => `${record.firstName} ${record.lastName} (${record.email})`} optionValue="id" />
      </ReferenceInput>
      <TextInput source="plateNumber" placeholder="XX-XX-XX" validate={required()} />
    </SimpleForm>
  </Create>
);

export const VehicleEdit = (props: ResourceComponentProps) => (
  <Edit {...props}>
    <SimpleForm>
    <TextField source="id" />
      <TextField source="salesforceId" />
      <TextField source="createdAt" />
      <TextField source="status" />
      <TextField source="user.id" label="User ID" />
      <TextField source="user.firstName" label="User name" />
      <ImageField source="vehicleImage" />
      <TextField source="plateNumber" />
      <TextField source="plateDate" />
      <TextField source="buildDate" />
      <TextField source="vin" />
      <TextField source="make" />
      <TextField source="model" />
      <TextField source="version" />
      <SelectInput
        source="status"
        options={{ label: "Status" }}
        choices={VehicleStatus}
        validate={required()}
      />
    </SimpleForm>
  </Edit>
);

