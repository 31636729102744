import {
  List,
  Datagrid,
  TextField,
  ResourceComponentProps,
  Create,
  SimpleForm,
  TextInput,
  required,
  Edit,
} from "react-admin";

export class OperatorUpdateCommand {
  private name: string;

  constructor(data: any) {
    this.name = data.name ?? "";
  }

  static fromJson(data: any) {
    return new OperatorUpdateCommand(data);
  }
}

const OperatorsListFilters = [
  <TextInput source="email" />
];

export const OperatorList = (props: ResourceComponentProps) => (
  <List {...props} filters={OperatorsListFilters}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="createdAt" />
      <TextField source="email" />
      <TextField source="name" />
    </Datagrid>
  </List>
);

export const OperatorCreate = (props: ResourceComponentProps) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="email" validate={required()} />
      <TextInput source="name" validate={required()} />
    </SimpleForm>
  </Create>
);

export const OperatorEdit = (props: ResourceComponentProps) => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextField source="createdAt" />
      <TextInput source="name" validate={required()} />
    </SimpleForm>
  </Edit>
);
