import React from "react";
import { Admin, Resource } from "react-admin";
import { CustomDataProvider } from "network/DataProvider";
import { createBrowserHistory } from "history";
import { Authenticator } from "@aws-amplify/ui-react";
import { CustomLayout } from "./layout/CustomLayout";
import OperatorsIcon from "@material-ui/icons/People";
import ApiKeysIcon from "@material-ui/icons/VpnKey";
import UsersIcon from "@material-ui/icons/AccountCircle";
import WorkshopsIcon from "@material-ui/icons/Store";
import ServicesIcon from "@material-ui/icons/RoomService";
import CategoriesIcon from "@material-ui/icons/Category";
import PerksIcon from "@material-ui/icons/Add";
import TranscriptsIcon from "@material-ui/icons/Message";
import LogsIcon from "@material-ui/icons/Adb";
import WorkflowsIcon from "@material-ui/icons/Build";
import VehiclesIcon from "@material-ui/icons/DirectionsCar";
import BookingsIcon from "@material-ui/icons/Book";
import { OperatorCreate, OperatorEdit, OperatorList } from "./resources/Operators";
import { WorkshopList, WorkshopShow } from "./resources/Workshops";
import { UserCreate, UserEdit, UserList, UserShow } from "./resources/Users";
import { VehicleCreate, VehicleEdit, VehicleList } from "./resources/Vehicles";
import "@aws-amplify/ui-react/styles.css"
import "./app.css"
import { ServiceList, ServiceShow } from "./resources/Services";
import { CategoryList, CategoryShow } from "./resources/Categories";
import { PerkList, PerkShow } from "./resources/Perks";
import { WorkflowCreate, WorkflowEdit, WorkflowList } from "./resources/Workflow";
import { ApiKeyCreate, ApiKeyList } from "./resources/ApiKeys";
import { BookingCreate, BookingEdit, BookingList } from "./resources/Bookings";
import { TranscriptList } from "./resources/Transcripts";
import { LogList } from "./resources/Logs";


const history = createBrowserHistory();
const dataProvider = new CustomDataProvider();

const App = () => {
  return (
    <Authenticator hideSignUp={true}>
      {() => 
        <Admin
          history={history}
          dataProvider={dataProvider}
          layout={CustomLayout}>
          <Resource
            name={"operators"}
            options={{ label: "Operators" }}
            icon={OperatorsIcon}
            list={OperatorList}
            create={OperatorCreate}
            edit={OperatorEdit}
          />
          <Resource
            name={"apiKeys"}
            options={{ label: "API Keys" }}
            icon={ApiKeysIcon}
            list={ApiKeyList}
            create={ApiKeyCreate}
          />
          <Resource
            name={"workshops"}
            options={{ label: "Workshops" }}
            icon={WorkshopsIcon}
            list={WorkshopList}
            show={WorkshopShow}
          />
          <Resource
            name={"services"}
            options={{ label: "Services" }}
            icon={ServicesIcon}
            list={ServiceList}
            show={ServiceShow}
          />
          <Resource
            name={"categories"}
            options={{ label: "Categories" }}
            icon={CategoriesIcon}
            list={CategoryList}
            show={CategoryShow}
          />
          <Resource
            name={"perks"}
            options={{ label: "Perks" }}
            icon={PerksIcon}
            list={PerkList}
            show={PerkShow}
          />
          <Resource
            name={"transcripts"}
            options={{ label: "Transcripts" }}
            icon={TranscriptsIcon}
            list={TranscriptList}
          />
          <Resource
            name={"logs"}
            options={{ label: "Logs" }}
            icon={LogsIcon}
            list={LogList}
          />
          <Resource
            name={"workflows"}
            options={{ label: "Workflows" }}
            icon={WorkflowsIcon}
            list={WorkflowList}
            create={WorkflowCreate}
            edit={WorkflowEdit}
          />
          <Resource
            name={"users"}
            options={{ label: "Users" }}
            icon={UsersIcon}
            list={UserList}
            create={UserCreate}
            edit={UserEdit}
            show={UserShow}
          />
          <Resource
            name={"vehicles"}
            options={{ label: "Vehicles" }}
            icon={VehiclesIcon}
            list={VehicleList}
            create={VehicleCreate}
            edit={VehicleEdit}
          />
          <Resource
            name={"bookings"}
            options={{ label: "Bookings" }}
            icon={BookingsIcon}
            list={BookingList}
            create={BookingCreate}
            edit={BookingEdit}
          />
        </Admin>
      }
  </Authenticator>
  );
};

export default App;
