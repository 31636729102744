import {
  List,
  Datagrid,
  TextField,
  ResourceComponentProps,
  TextInput,
  Show,
  SimpleShowLayout,
} from "react-admin";

const ServiceListFilters = [
  <TextInput source="id" />,
  <TextInput source="name" />,
];

export const ServiceList = (props: ResourceComponentProps) => (
  <List {...props} bulkActionButtons={false} filters={ServiceListFilters}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
    </Datagrid>
  </List>
);


export const ServiceShow = (props: ResourceComponentProps) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="salesforceId" />
      <TextField source="name" />
    </SimpleShowLayout>
  </Show>
);

