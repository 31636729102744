import axios from "network";
import {
  List,
  Datagrid,
  TextField,
  ResourceComponentProps,
  Create,
  SimpleForm,
  TextInput,
  required,
  Edit,
  TopToolbar,
  ShowActionsProps,
  Show,
  TabbedShowLayout,
  Tab,
  useRedirect,
  SaveButton,
  DeleteButton,
} from "react-admin";

export class UserUpdateCommand {
  private firstName: string;
  private lastName: string;
  private phone: string;

  constructor(data: any) {
    this.firstName = data.firstName ?? "";
    this.lastName = data.lastName ?? "";
    this.phone = data.phone ?? "";
  }

  static fromJson(data: any) {
    return new UserUpdateCommand(data);
  }
}

const UsersListFilters = [
  <TextInput source="email" />
];

export const UserList = (props: ResourceComponentProps) => (
  <List {...props} bulkActionButtons={false} filters={UsersListFilters}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="createdAt" />
      <TextField source="email" />
      <TextField source="firstName" />
      <TextField source="lastName" />
    </Datagrid>
  </List>
);

export const UserCreate = (props: ResourceComponentProps) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="email" validate={required()} />
      <TextInput source="firstName" validate={required()} />
      <TextInput source="lastName" validate={required()} />
      <TextInput source="phone" validate={required()} />
    </SimpleForm>
  </Create>
);

const UserEditActions = (props: ShowActionsProps) => (
  <TopToolbar>
    <SaveButton {...props} />
    <DeleteButton {...props} />
  </TopToolbar>
);

export const UserEdit = (props: ResourceComponentProps) => (
  <Edit {...props}>
    <SimpleForm toolbar={<UserEditActions />}>
      <TextField source="id" />
      <TextField source="salesforceId" />
      <TextField source="createdAt" />
      <TextField source="email" />
      <TextInput source="firstName" validate={required()} />
      <TextInput source="lastName" validate={required()} />
      <TextInput source="phone" validate={required()} />
    </SimpleForm>
  </Edit>
);

const PushNotificationShowActions = (props: ShowActionsProps) => (
  <TopToolbar>
    <SaveButton {...props} />
  </TopToolbar>
);

export const UserShow = (props: ResourceComponentProps) => {
  const redirect = useRedirect();
  const save = async (values: any) => {
    await axios.post(`users/${values.id}/devices/notification`, { title: values.title, message: values.message });
    redirect("show", props.basePath, values.id);
  }

  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Summary">
          <TextField source="id" />
          <TextField source="salesforceId" />
          <TextField source="createdAt" />
          <TextField source="email" />
          <TextField source="firstName" />
          <TextField source="lastName" />
          <TextField source="phone" />
        </Tab>
        <Tab label="Push Notifications">
          <Create>
            <SimpleForm save={save} toolbar={<PushNotificationShowActions />}>
              <TextInput source="title" validate={required()} />
              <TextInput source="message" validate={required()} />
            </SimpleForm>
          </Create>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
